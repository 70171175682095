import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSShadowPalaceF4Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Floor 4</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_floor_4.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Floor 4</h1>
          <h2>Guide for the fourth floor of Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="MVP employees" />
        <div className="employee-container">
          <Employee slug="regina-maccready" mode="icon" enablePopover />
        </div>
        <p>
          Since Janus is this biggest issue on this Floor, Regina shines here as
          she can make it a lot weaker.
        </p>
        <div className="employee-container">
          <Employee
            slug="awakened-yuna-springfield"
            mode="icon"
            enablePopover
          />
        </div>
        <p>
          If you have Awakened Yuna, you can slap her in your team for Rooms 1-4
          and she will carry you. She's that good.
        </p>
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <p>
          Starting from this floor, enemies' Special skills can wipe your team,
          having hit absorbers is necessary - both Kyle's walls and Edel's
          summons fill that role really well.
        </p>
        <div className="employee-container">
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="janus" mode="icon" enablePopover />
        </div>
        <p>
          Flying characters that can deal damage and avoid being stunned are
          great here - but Janus isn't available in Global yet, so you kinda
          need Rosaria.
        </p>
        <div className="employee-container">
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <p>
          Shin Jia fulfills three roles here - buffer, hit absorber and CC bot.
        </p>
        <SectionHeader title="Enemies" />
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_M_FUTURE_HARPY_LAND.webp"
          alt="Shadow Enemy"
        />
        <p>Janus on drugs. Far sturdier and scarier with his upped damage.</p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_BD_RYAN.webp"
          alt="Shadow Enemy"
        />
        <p>
          His kit is different, but overall he pushes your team back toward the
          ship and keeps stunning them.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ALPHA_CHOI.webp"
          alt="Shadow Enemy"
        />
        <p>
          His -50% ATK debuff is quite annoying and it takes some time to kill
          him.
        </p>
        <h4>Ship</h4>
        <p>
          Works way different compared to the ship we can use. Special attack
          fires lasers that deal damage and push the team back (this attack also
          hits air). Ultimate is battlefield-wide aoe that CC and damages your
          team.
        </p>
        <SectionHeader title="Restrictions" />
        <ul>
          <li>Room 1 - Defenders are banned</li>
          <li>Room 2 - Supporters are banned</li>
          <li>Room 3 - Snipers are banned</li>
          <li>Room 4 - Max 25 deployment cost</li>
          <li>Room 5 - Max 25 deployment cost and Awakened units are banned</li>
        </ul>
        <SectionHeader title="Important" />
        <p>
          This floor can be cleared with multiple teams and you don't have to
          follow the guide 100%. If you for example have Ifrit or Chifuyu geared
          well, you can basically use on every room as Strikers are getting a
          free pass on this floor and aren't banned even once.
        </p>
        <SectionHeader title="Room 1" />
        <p>
          First Room is already one of the hardest on this floor as you have to
          face Janus. There's so many healers here because his damage is stupid.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="regina-maccready" mode="icon" enablePopover />
          <Employee slug="nanahara-chinatsu" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 2" />
        <p>
          Supporters are banned on this floor, so you have to clear it quickly
          or risk being chipped down by enemies.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="hilde" mode="icon" enablePopover />
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 3" />
        <p>
          Your goal here is to overhelm evil Gabriel and do it from safe
          distance to avoid being massively debuffed by his aura.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="regina-maccready" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 4" />
        <p>
          Evil Gabriel and evil Ryan are the enemies you will fight here.
          Overall, it's a pretty straightforward room.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="nanahara-chinatsu" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 5" />
        <p>
          Well. This is the room where a lot of players might become stuck. You
          have to face all three shadows here and things will get wild if you
          allow them to overwhelm you.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="regina-maccready" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="nanahara-chinatsu" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Video guide" />
        <p>
          The video guide uses different teams, so if you lack some of the
          employees in the teams above, you can try Cymen's approach.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="phQNDpXBdrA" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceF4Page;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace 4 guide | Counter Side | Prydwen Institute"
    description="Guide for the fourth floor of Shadow Palace."
  />
);
